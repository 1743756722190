import logo from './assets/images/Jatra-Spirits-Logo.png';
import SazeracLogo from './assets/images/Sazerac-Logo.png';
//import brandLogos from './assets/images/jatra-spirits-brands.jpg';
//import productsPricing from './assets/images/jatra-spirits-products-pricing.jpg'
import './App.css';

function App() {
  const brandLogosUrl = 'https://lh3.googleusercontent.com/d/1psQHaoIOj64k0a0b1b5pXY0pO2qfFqXY';
  const productsPricingUrl = 'https://lh3.googleusercontent.com/d/1IvCXdg9mNyPihTEBtUBtop2hn4Z_oYKB';
  //https://drive.google.com/file/d/1psQHaoIOj64k0a0b1b5pXY0pO2qfFqXY/view?usp=drive_link
  //https://drive.google.com/file/d/1IvCXdg9mNyPihTEBtUBtop2hn4Z_oYKB/view?usp=drive_link

  //https://drive.google.com/file/d/1IvCXdg9mNyPihTEBtUBtop2hn4Z_oYKB/view?usp=sharing
  //https://drive.google.com/file/d/1psQHaoIOj64k0a0b1b5pXY0pO2qfFqXY/view?usp=sharing
  //https://drive.google.com/uc?export=view&id=1IvCXdg9mNyPihTEBtUBtop2hn4Z_oYKB

  return (
    <div className="js-app flex flex-col min-h-screen">
      <div className="container mx-auto text-center">
      <header className="js-header overflow-hidden py-4 mb-4 border-b border-slate-200">
       
          <div className="js-logo inline-block">
            <img src={logo} title="Jatra Spirits" alt="Jatra Spirits Logo" />
          </div>
        
      </header>
      </div>
      <div className="clear-both"></div>
      <main>
        <div className="container mx-auto text-center">
         
         
          
          <img className="inline-block my-4 mx-w-3xl js-sazerac" src={SazeracLogo} alt="Sazerac Logo" />
          {/* <span className='block text-xl'>Jatra Spirits is an authorized importer and distributor of products of Sazerac in Nepal.</span> */}
          <div className="clear-both"></div>
            {/* <img className="inline-block mb-0" src={brandLogos} alt="Brand Logos" />
            <img className="inline-block mb-4" src={productsPricing} alt="Jatra Spirits Products Pricing" /> */}
            <img className="inline-block mb-0" src={brandLogosUrl} alt="Brand Logos" />
            <img className="inline-block mb-4" src={productsPricingUrl} alt="Jatra Spirits Products Pricing" />
            {/* <iframe src="https://drive.google.com/file/d/1IvCXdg9mNyPihTEBtUBtop2hn4Z_oYKB/preview" width="640" height="480" allow="autoplay" title="Pricing"></iframe> */}
          
          {/* <div className="mx-auto max-w-3xl font-display text-5xl font-medium tracking-tight py-4">
            <span className="text-2xl block">Imported & Distributed</span>
            <span className="text-2xl block">by</span>
            <h2 className='text-3xl'>Jatra Spirits Pvt. Ltd.</h2>
          </div> */}
        </div>
      </main>

      <footer className="text-sm leading-6 sticky mt-auto">
        <div className="container mx-auto text-center">
          <div className="py-4 border-t border-slate-200 sm:flex justify-center text-slate-500 dark:border-slate-200/5">
            <div className="mb-6 sm:mb-0 sm:flex">
              <p>© 2024 Jatra Spirits Pvt. Ltd.</p>
            </div>
          </div>
        </div>
      </footer>

      
    </div>
  );
}

export default App;
